.product-container {
  width: 70%;
}

.gallery {
  border-color: rgb(248, 249, 250);
}

.active {
  border-color: rgb(248, 249, 250) !important;
}

.image-gallery-thumbnail:hover {
  border-color: rgb(248, 249, 250) !important;
}

.image-gallery-image{
  border: 1px solid rgb(0, 0, 0,0.1);
  border-radius: 3%;
}

.text-editor img {
  width: 100%;
}

.whatsup {
  position: fixed;
  left: 10px;
  bottom: 80px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #0098F7;
  border: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, .25);
  color: white;
  z-index: 10;
}

.buy2 {
  position: fixed;
  bottom: 0px;
  height: 50px;
  z-index: 10;
}

.buy {

 
  width: 300px;
  height: 50px;
  border-radius: 5px;
  background-color: #05808F;
  border: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, .25);
  color: white;
  animation: horizontal-shaking  2s;
  animation-iteration-count: infinite;  
}

.offer2 {
  box-shadow: 0 0 10px rgba(0, 0, 0, .25);
  animation: horizontal-shaking  2s;
  animation-iteration-count: infinite;  
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
 }




/* media queries (medium devices) */

@media screen and (max-width: 1400px) {

  .product-container {
    width: 95%
  }

}

/* media queries (small devices) */

@media screen and (max-width: 600px) {
  .product-container {
    width: 95%
  }

}