.form2{
    border: 1px solid #93e026;
    border-width: 2px;
}

.offer{
    border: 1px solid #93e026;
    border-width: 1px;
}

input[type="text"],
select {
      /* border: 1px solid #dadada!important; */
    box-shadow: none!important;
    outline: none;
    border-radius: 3px;
    border: 1px solid #dadada;
    padding: 10px 15px;
    background-color: #fbfbfb;
    height: 50px;
    width: 100%;
    transition: .05s ease;
    /* caret-color: #acca40; */
    font-size: 14px;
}


input[type=text]:focus {
    border: 2px solid #cccccc !important;
}

select:focus {
    border: 2px solid #ccc !important;
}

input[type="radio"]:checked {
    background-color: rgb(0, 0, 0);
}

.form-control1:disabled, .form-control[readonly] {
    background-color: #ffffff !important;
    opacity: 1;
}

.form-control {
    font-size: 14px;
    color: #757575 !important;
   
}
.bg-light {
    background-color: #fbfbfb!important;
}
.bg-warning {
    
    font-size: 15px !important;
}
.bg-success {
    background-color: #1ab86e!important;
}
.p-0 {
    padding-right: 5px!important;
    font-size: 14px !important;
}
.mb-3 {
    margin-bottom: 6px!important;
}
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    height: 46px;
    border-color: #0d6efd00 !important;
}
.btn-primary {
 
       height: 46px;
    border-color: #0d6efd00 !important;
animation: horizontal-shaking 1s;
    -webkit-animation-iteration-count: infinite;
}
.p-1 {
  
    padding: 5px 12px !important;
   
    border: 1px solid #e5e5e5 ;
    border-radius: 3px !important;
    cursor: pointer !important;
}
.border-2 {
    border-width: 1px!important;
}
.btn-primary:hover {
  
    height: 46px;
    border-color: #0d6dfd00;
animation: horizontal-shaking 0s !important;
    -webkit-animation-iteration-count: infinite !important;
   
}
.my-1 {
    margin-top: 0.25rem!important;
    margin-bottom: 0.25rem!important;
    flex-shrink: 0;
    width: auto;
    max-width: none;
    padding-right: 1px;
    padding-left: 1px;
}
.p-2-2 {
    padding: 0.5rem!important;
    background-color: #fbfbfb;
    border: 1px solid #dadada;
    border-radius: 3px;
    box-shadow: none!important;
    font-size: 14px;
    height: 50px;
    outline: none;
    padding: 10px 15px;
    transition: .05s ease;
    width: 100%;
}
