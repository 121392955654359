.product-container {
  width: 70%;
  min-height: 350px;
}

.product {
  width: 18%;
  font-size: 15px !important;
  /*animation: fadeInUp 1.5s;*/
}

.product-card {
  height: auto;
  transition: transform .6s;
  /* Animation */
}

.product-card:hover {
  cursor: pointer;
  transform: scale(1.05);

}



.div-image {
  position: relative;
}

.div-image:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.product-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover; 
}



/* media queries (medium devices) */

@media screen and (max-width: 1024px) {

  .product-container {
    width: 95%
  }

  .product {
    width: 30%;
  }

  .product-card {
    height: auto;
    transition: transform .6s;
    /* Animation */
  }

  /*.product-img {
    height: 165px;
  }*/
}

/* media queries (small devices) */

@media screen and (max-width: 600px) {
  .product-container {
    width: 95%
  }

  .product {
    width: 47%;
  }

  .product-card {
    height: auto;
  }

  .product-img {
    width: 100%;
    /*height: 165px;*/
  }

}

@keyframes fadeOut {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

.fadeOut {
  animation-name: fadeOut
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0)
  }

  to {
    opacity: 1;
    transform: none
  }
}

.fadeInDown {
  animation-name: fadeInDown
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: rotateY(150deg)
  }

  to {
    opacity: 1;
    transform: none
  }
}

.fadeInUp {
  animation-name: fadeInUp
}