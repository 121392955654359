@import "~react-image-gallery/styles/css/image-gallery.css";
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@500&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: 'Cairo', sans-serif;
  letter-spacing: 0.5px;
}


