.filter{
  width: 19%;
  border: 1px solid #7F7F7F;
  border-width: 1px;
  border-radius: 3px;
}

.filter-contanier {
  width: 70%;
}

.filter:hover {
  cursor: pointer;
  border: 1px solid #A5c436;
}

/* media queries (medium devices) */

@media screen and (max-width: 1024px) {
  .filter {
    width: 30%;
    margin-top: 5px;
  }
}

/* media queries (small devices) */

@media screen and (max-width: 600px) {
  .filter {
    width: 45%;
    margin-top: 5px;
  }
  .filter-contanier {
    width: 95%;
  }
}
