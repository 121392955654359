.message {
    width: 33%;
}

.recu {
    width: 50%;
}

/* media queries (medium devices) */

@media screen and (max-width: 1024px) {
    .message {
        width: 50%;
    }
    .recu {
        width: 70%;
    }
}

/* media queries (small devices) */

@media screen and (max-width: 600px) {

    .message {
        width: 90%;
    }

    .recu {
        width: 95%;
    }
}